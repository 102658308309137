// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import Autosize from "autosize";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "./OptionUtils.bs.js";

function perform(f, id) {
  OptionUtils.mapWithDefault(Curry.__1(f), undefined, Caml_option.nullable_to_opt(document.getElementById(id)));
}

function create(id) {
  perform((function (prim) {
          Autosize(prim);
        }), id);
}

function update(id) {
  var partial_arg = Autosize;
  perform((function (param) {
          partial_arg.update(param);
        }), id);
}

function destroy(id) {
  var partial_arg = Autosize;
  perform((function (param) {
          partial_arg.destroy(param);
        }), id);
}

export {
  perform ,
  create ,
  update ,
  destroy ,
}
/* autosize Not a pure module */
