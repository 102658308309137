// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "./utils/I18n.bs.js";
import * as Curry from "../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../node_modules/bs-fetch/src/Fetch.bs.js";
import * as Js_promise from "../../../../../shared/node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as $$Notification from "./Notification.bs.js";
import * as Caml_exceptions from "../../../../../shared/node_modules/rescript/lib/es6/caml_exceptions.js";

var UnexpectedResponse = /* @__PURE__ */Caml_exceptions.create("Api.UnexpectedResponse");

var partial_arg = "components.Api";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function apiErrorTitle(x) {
  if (x.RE_EXN_ID === UnexpectedResponse) {
    return String(x._1);
  } else {
    return t(undefined, undefined, "error_notification_title");
  }
}

function acceptOrRejectResponse(response) {
  if (response.ok || response.status === 422) {
    return Fetch.$$Response.json(response);
  } else {
    return Promise.reject({
                RE_EXN_ID: UnexpectedResponse,
                _1: response.status
              });
  }
}

function handleResponseError(error) {
  var title = apiErrorTitle(error);
  $$Notification.error(title, t(undefined, undefined, "error_notification_body"));
}

function handleResponseJSON(json, responseCB, errorCB, notify) {
  var error = Json_decode.optional((function (param) {
          return Json_decode.field("error", Json_decode.string, param);
        }), json);
  if (error !== undefined) {
    if (notify) {
      $$Notification.error(ts(undefined, undefined, "notifications.something_wrong"), error);
    }
    return Curry._1(errorCB, undefined);
  } else {
    return Curry._1(responseCB, json);
  }
}

function handleResponse(responseCB, errorCB, notifyOpt, promise) {
  var notify = notifyOpt !== undefined ? notifyOpt : true;
  Js_promise.$$catch((function (error) {
          Curry._1(errorCB, undefined);
          console.log(error);
          return Promise.resolve(notify ? handleResponseError(error) : undefined);
        }), Js_promise.then_((function (json) {
              return Promise.resolve(handleResponseJSON(json, responseCB, errorCB, notify));
            }), Js_promise.then_(acceptOrRejectResponse, promise)));
}

function sendPayload(url, payload, responseCB, errorCB, method_) {
  var param;
  var param$1 = fetch(url, Fetch.RequestInit.make(method_, {
              "Content-Type": "application/json"
            }, Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined));
  handleResponse(responseCB, errorCB, param, param$1);
}

function sendFormData(url, formData, responseCB, errorCB) {
  var param;
  var param$1 = fetch(url, Fetch.RequestInit.make(/* Post */2, undefined, Caml_option.some(formData), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined, undefined)(undefined));
  handleResponse(responseCB, errorCB, param, param$1);
}

function get(url, responseCB, errorCB, notify) {
  handleResponse(responseCB, errorCB, notify, fetch(url));
}

function create(url, payload, responseCB, errorCB) {
  sendPayload(url, payload, responseCB, errorCB, /* Post */2);
}

function update(url, payload, responseCB, errorCB) {
  sendPayload(url, payload, responseCB, errorCB, /* Patch */8);
}

export {
  UnexpectedResponse ,
  t ,
  ts ,
  apiErrorTitle ,
  acceptOrRejectResponse ,
  handleResponseError ,
  handleResponseJSON ,
  handleResponse ,
  sendPayload ,
  sendFormData ,
  get ,
  create ,
  update ,
}
/* I18n Not a pure module */
