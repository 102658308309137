// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../utils/I18n.bs.js";
import * as Curry from "../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "./FaIcon.bs.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as OptionUtils from "../utils/OptionUtils.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.HelpIcon";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

import "./HelpIcon.css"
;

function onWindowClick(helpVisible, setHelpVisible, _event) {
  if (helpVisible) {
    return Curry._1(setHelpVisible, (function (param) {
                  return false;
                }));
  }
  
}

function toggleHelp(setHelpVisible, _event) {
  return Curry._1(setHelpVisible, (function (helpVisible) {
                return !helpVisible;
              }));
}

function alignmentClass(alignment) {
  switch (alignment) {
    case /* AlignLeft */0 :
        return " start-0";
    case /* AlignRight */1 :
        return " end-0";
    case /* AlignCenter */2 :
        return " help-icon__help-container--center";
    
  }
}

function responsiveAlignmentClass(responsiveAlignment) {
  if (responsiveAlignment.TAG === /* NonResponsive */0) {
    return alignmentClass(responsiveAlignment._0);
  }
  var mobileClass = alignmentClass(responsiveAlignment._0);
  var desktopClass;
  switch (responsiveAlignment._1) {
    case /* AlignLeft */0 :
        desktopClass = " md:right-auto md:start-0";
        break;
    case /* AlignRight */1 :
        desktopClass = " md:left-auto md:end-0";
        break;
    case /* AlignCenter */2 :
        desktopClass = " help-icon__help-container--md-center";
        break;
    
  }
  return mobileClass + (" " + desktopClass);
}

function HelpIcon(Props) {
  var classNameOpt = Props.className;
  var link = Props.link;
  var responsiveAlignmentOpt = Props.responsiveAlignment;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var responsiveAlignment = responsiveAlignmentOpt !== undefined ? responsiveAlignmentOpt : ({
        TAG: /* NonResponsive */0,
        _0: /* AlignCenter */2
      });
  var match = React.useState(function () {
        return false;
      });
  var setHelpVisible = match[1];
  var helpVisible = match[0];
  React.useEffect((function () {
          var curriedFunction = function (param) {
            return onWindowClick(helpVisible, setHelpVisible, param);
          };
          var $$window$1 = window;
          var removeEventListener = function (param) {
            $$window$1.removeEventListener("click", curriedFunction);
          };
          if (helpVisible) {
            $$window$1.addEventListener("click", curriedFunction);
            return removeEventListener;
          } else {
            removeEventListener(undefined);
            return ;
          }
        }), [helpVisible]);
  return React.createElement("div", {
              className: "inline-block relative " + className,
              onClick: (function (param) {
                  return Curry._1(setHelpVisible, (function (helpVisible) {
                                return !helpVisible;
                              }));
                })
            }, React.createElement(FaIcon.make, {
                  classes: "fas fa-question-circle rtl:scale-x-[-1] hover:text-gray-600 cursor-pointer"
                }), helpVisible ? React.createElement("div", {
                    className: "help-icon__help-container overflow-y-auto mt-1 border border-gray-900 absolute z-50 px-4 py-3 shadow-lg leading-snug rounded-lg bg-gray-900 text-white max-w-xs" + responsiveAlignmentClass(responsiveAlignment),
                    onClick: (function ($$event) {
                        $$event.stopPropagation();
                      })
                  }, children, OptionUtils.$$default(null, OptionUtils.map((function (link) {
                              return React.createElement("a", {
                                          className: "block mt-1 text-blue-300 hover:text-blue:200",
                                          href: link,
                                          target: "_blank"
                                        }, React.createElement(FaIcon.make, {
                                              classes: "fas fa-external-link-square-alt rtl:-rotate-90"
                                            }), React.createElement("span", {
                                              className: "ms-1"
                                            }, t(undefined, undefined, "read_more")));
                            }), link))) : null);
}

function makeFromJson(json) {
  var responsiveAlignment = Belt_Option.map(Json_decode.optional((function (param) {
              return Json_decode.field("responsiveAlignment", Json_decode.string, param);
            }), json), (function (responsiveAlignment) {
          switch (responsiveAlignment) {
            case "nrc" :
                return {
                        TAG: /* NonResponsive */0,
                        _0: /* AlignCenter */2
                      };
            case "nrl" :
                return {
                        TAG: /* NonResponsive */0,
                        _0: /* AlignLeft */0
                      };
            case "nrr" :
                return {
                        TAG: /* NonResponsive */0,
                        _0: /* AlignRight */1
                      };
            case "rcl" :
                return {
                        TAG: /* Responsive */1,
                        _0: /* AlignCenter */2,
                        _1: /* AlignLeft */0
                      };
            case "rcr" :
                return {
                        TAG: /* Responsive */1,
                        _0: /* AlignCenter */2,
                        _1: /* AlignRight */1
                      };
            case "rlc" :
                return {
                        TAG: /* Responsive */1,
                        _0: /* AlignLeft */0,
                        _1: /* AlignCenter */2
                      };
            case "rlr" :
                return {
                        TAG: /* Responsive */1,
                        _0: /* AlignLeft */0,
                        _1: /* AlignRight */1
                      };
            case "rrc" :
                return {
                        TAG: /* Responsive */1,
                        _0: /* AlignRight */1,
                        _1: /* AlignCenter */2
                      };
            case "rrl" :
                return {
                        TAG: /* Responsive */1,
                        _0: /* AlignRight */1,
                        _1: /* AlignLeft */0
                      };
            default:
              return {
                      TAG: /* NonResponsive */0,
                      _0: /* AlignCenter */2
                    };
          }
        }));
  return HelpIcon({
              className: Json_decode.optional((function (param) {
                      return Json_decode.field("className", Json_decode.string, param);
                    }), json),
              link: Json_decode.optional((function (param) {
                      return Json_decode.field("link", Json_decode.string, param);
                    }), json),
              responsiveAlignment: responsiveAlignment,
              children: React.createElement("div", {
                    dangerouslySetInnerHTML: {
                      __html: Json_decode.field("children", Json_decode.string, json)
                    }
                  })
            });
}

var make = HelpIcon;

export {
  str ,
  t ,
  onWindowClick ,
  toggleHelp ,
  alignmentClass ,
  responsiveAlignmentClass ,
  make ,
  makeFromJson ,
}
/*  Not a pure module */
