// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_array from "../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Js_string from "../../../../../shared/node_modules/rescript/lib/es6/js_string.js";
import * as Caml_option from "../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as StringUtils from "./utils/StringUtils.bs.js";

function initials(name) {
  return Js_array.joinWith("", Js_array.map((function (word) {
                    return Js_string.slice(0, 1, word);
                  }), Js_array.slice(0, 2, Js_string.split(" ", name))));
}

function Avatar(Props) {
  var colors = Props.colors;
  var name = Props.name;
  var className = Props.className;
  var match = colors !== undefined ? [
      colors[0],
      colors[1]
    ] : StringUtils.toColor(name);
  var tmp = {
    title: name,
    version: "1.1",
    viewBox: "0 0 100 100",
    xmlns: "http://www.w3.org/2000/svg"
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("svg", tmp, React.createElement("circle", {
                  cx: "50",
                  cy: "50",
                  fill: match[0],
                  r: "50"
                }), React.createElement("text", {
                  alignmentBaseline: "middle",
                  dominantBaseline: "middle",
                  fill: match[1],
                  fontFamily: "sans-serif",
                  fontSize: "42",
                  textAnchor: "middle",
                  x: "50",
                  y: "54"
                }, initials(name)));
}

function makeFromJson(json) {
  return Avatar({
              colors: Json_decode.optional((function (param) {
                      return Json_decode.field("id", (function (param) {
                                    return Json_decode.pair(Json_decode.string, Json_decode.string, param);
                                  }), param);
                    }), json),
              name: Json_decode.field("name", Json_decode.string, json),
              className: Json_decode.optional((function (param) {
                      return Json_decode.field("placeholder", Json_decode.string, param);
                    }), json)
            });
}

var make = Avatar;

export {
  initials ,
  make ,
  makeFromJson ,
}
/* react Not a pure module */
