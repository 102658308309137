// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../../shared/node_modules/rescript/lib/es6/string.js";
import * as Js_array from "../../../../../../shared/node_modules/rescript/lib/es6/js_array.js";
import * as Js_string from "../../../../../../shared/node_modules/rescript/lib/es6/js_string.js";
import * as Caml_array from "../../../../../../shared/node_modules/rescript/lib/es6/caml_array.js";
import * as Belt_Option from "../../../../../../shared/node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";

function parameterize(t) {
  return Js_string.replaceByRe(/^-|-$/gmi, "", Js_string.replaceByRe(/[^0-9a-zA-Z]+/gi, "-", t.toLowerCase()));
}

function paramToId(param) {
  return Belt_Option.map(Belt_Option.map(Caml_option.null_to_opt(/^\d+/g.exec(param)), (function (prim) {
                    return prim;
                  })), (function (param) {
                return Js_array.joinWith("", param);
              }));
}

function includes(caseInsensitiveOpt, source, target) {
  var caseInsensitive = caseInsensitiveOpt !== undefined ? caseInsensitiveOpt : true;
  var match = caseInsensitive ? [
      source.toLocaleLowerCase(),
      target.toLocaleLowerCase()
    ] : [
      source,
      target
    ];
  return Js_string.includes(match[0], match[1]);
}

function isPresent(t) {
  return t.trim() !== "";
}

function test(value, text) {
  var tester = new RegExp(value);
  return tester.test(text);
}

var colors = [
  [
    "#ff4040",
    false
  ],
  [
    "#7f2020",
    false
  ],
  [
    "#cc5c33",
    false
  ],
  [
    "#734939",
    false
  ],
  [
    "#bf9c8f",
    false
  ],
  [
    "#995200",
    false
  ],
  [
    "#4c2900",
    false
  ],
  [
    "#f2a200",
    false
  ],
  [
    "#ffd580",
    true
  ],
  [
    "#332b1a",
    false
  ],
  [
    "#4c3d00",
    false
  ],
  [
    "#ffee00",
    true
  ],
  [
    "#b0b386",
    false
  ],
  [
    "#64664d",
    false
  ],
  [
    "#6c8020",
    false
  ],
  [
    "#c3d96c",
    true
  ],
  [
    "#143300",
    false
  ],
  [
    "#19bf00",
    false
  ],
  [
    "#53a669",
    false
  ],
  [
    "#bfffd9",
    true
  ],
  [
    "#40ffbf",
    true
  ],
  [
    "#1a332e",
    false
  ],
  [
    "#00b3a7",
    false
  ],
  [
    "#165955",
    false
  ],
  [
    "#00b8e6",
    false
  ],
  [
    "#69818c",
    false
  ],
  [
    "#005ce6",
    false
  ],
  [
    "#6086bf",
    false
  ],
  [
    "#000e66",
    false
  ],
  [
    "#202440",
    false
  ],
  [
    "#393973",
    false
  ],
  [
    "#4700b3",
    false
  ],
  [
    "#2b0d33",
    false
  ],
  [
    "#aa86b3",
    false
  ],
  [
    "#ee00ff",
    false
  ],
  [
    "#bf60b9",
    false
  ],
  [
    "#4d3949",
    false
  ],
  [
    "#ff00aa",
    false
  ],
  [
    "#7f0044",
    false
  ],
  [
    "#f20061",
    false
  ],
  [
    "#330007",
    false
  ],
  [
    "#d96c7b",
    false
  ]
];

function stringToInt(name) {
  var aux = function (_sum, _remains) {
    while(true) {
      var remains = _remains;
      var sum = _sum;
      if (remains === "") {
        return sum;
      }
      var firstCharacter = Js_string.slice(0, 1, remains);
      var remains$1 = Js_string.sliceToEnd(1, remains);
      _remains = remains$1;
      _sum = sum + Js_string.charCodeAt(0, firstCharacter);
      continue ;
    };
  };
  return aux(0.0, name) | 0;
}

function toColor(t) {
  var index = stringToInt(t) % 42;
  var match = Caml_array.get(colors, index);
  return [
          match[0],
          match[1] ? "#000000" : "#FFFFFF"
        ];
}

function lengthBetween(allowBlankOpt, t, min, max) {
  var allowBlank = allowBlankOpt !== undefined ? allowBlankOpt : true;
  var tLen = $$String.trim(t).length;
  if (tLen > 0) {
    if (min <= tLen) {
      return tLen <= max;
    } else {
      return false;
    }
  } else {
    return allowBlank;
  }
}

export {
  parameterize ,
  paramToId ,
  includes ,
  isPresent ,
  test ,
  colors ,
  stringToInt ,
  toColor ,
  lengthBetween ,
}
/* No side effect */
