// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../../shared/node_modules/rescript/lib/es6/curry.js";
import * as Caml_option from "../../../../../../shared/node_modules/rescript/lib/es6/caml_option.js";

function $$default(e, v) {
  if (v !== undefined) {
    return Caml_option.valFromOption(v);
  } else {
    return e;
  }
}

function toString(option) {
  return $$default("", option);
}

function map(f, v) {
  if (v !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(v)));
  }
  
}

function flatMap(f, v) {
  return $$default(undefined, map(f, v));
}

function mapWithDefault(f, d, v) {
  return $$default(d, map(f, v));
}

export {
  $$default ,
  $$default as default,
  toString ,
  map ,
  flatMap ,
  mapWithDefault ,
}
/* No side effect */
